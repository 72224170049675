import oss from 'ali-oss';
import moment from 'moment';
import {message} from "ant-design-vue"
import { randomString } from '@/utils/string.js'

const client = (bucket) => {
  return new oss({
    accessKeyId: 'LTAIbW3TXatTSBZH',
    accessKeySecret: 'nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW',
    region: 'oss-cn-qingdao', //
    bucket: bucket,//
    secure: true,
  });
}

// 创建 OSS 客户端对象
const createOssClientTemp = (bucket) => {
  return new Promise((resolve) => {
    resolve(client(bucket))
  })
}

// OSS 文件下载
export const ossDownloadFile = (bucket, key, fileName) => {
  createOssClientTemp(bucket).then(client => {
    let url = client.signatureUrl(key, {
      response: {
        'Content-Disposition': `attachment;filename=${encodeURIComponent(fileName)}`
      },
      expires: 3600
    })
    window.location.href = url
  })
}

const uploadPath = (file) => {
  // 上传文件的路径，使用日期命名文件目录
  return `${moment().format('YYYYMMDD')}/${file.uid}-${randomString(20)}.${file.name.substring(file.name.lastIndexOf(".") + 1)}`
}
const UploadToOss = (file, bucket, obj) => {
  const url = uploadPath(file)
  return new Promise((resolve, reject) => {
    client(bucket).multipartUpload(url, file, obj).then(data => {
      resolve(data);
    }).catch(error => {
      reject(error)
    })
  })
}

const upload = (file, bucket, obj) => {
  return new Promise((resolve) => {
    let isJpgOrPng = true, isLt2M = true
    if(bucket == "softmall-images") {
      isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === "image/jpg" || file.type === "image/gif";
      if (!isJpgOrPng) {
        message.error('请上传PNG、JPG、GIF、JPEG图片格式！');
      }
      isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('上传图片必须小于 2MB!');
      }
    }
    if(isJpgOrPng && isLt2M) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        UploadToOss(file, bucket, obj).then(data => {
          if(data.res.status == 200) {
            resolve(data.name)
          } else {
            message.error('图片上传失败!');
          }
        })
      }
    } else {
      resolve (isJpgOrPng && isLt2M)
    }
  })
}


export default upload